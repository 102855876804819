<template>
  <div class="container booking-requests-container">
    <div
      class="
        col-12
        mt-3
        d-flex
        flex-md-row flex-column
        justify-content-between
        align-items-center
      "
    >
      <h1>Booking Requests</h1>
      <CopyBookingPortalUrl />
    </div>
    <div class="col-12 mt-3">
      <booking-requests-list />
    </div>
  </div>
</template>
<script>
import BookingRequestsList from "@/components/booking/BookingRequestsList.vue";
import CopyBookingPortalUrl from "@/components/booking/CopyBookingPortalUrl.vue";
export default {
  components: {
    BookingRequestsList,
    CopyBookingPortalUrl,
  },
  name: "booking-requests",
};
</script>
